import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from "@angular/common/http";
import {CoreModule} from "../../../core/src/modules/core/core.module";
import {AuthGuard} from "./auth.guard";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from "primeng/inputtextarea";
import {InputMaskModule} from "primeng/inputmask";
import {InputNumberModule} from "primeng/inputnumber";
import {ImageUploadComponent} from "../../../core/src/standalone/image-upload/image-upload.component";
import {MessageService} from "primeng/api";
import {ToastModule} from "primeng/toast";
import {PreviewImageComponent} from "../../../core/src/standalone/preview-image/preview-image.component";
import {ImageModule} from "primeng/image";
import {CommonModule} from "@angular/common";
import {CalendarModule} from "primeng/calendar";
import {DropdownModule} from "primeng/dropdown";

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CoreModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    InputTextareaModule,
    InputMaskModule,
    InputNumberModule,
    ImageUploadComponent,
    ToastModule,
    PreviewImageComponent,
    ImageModule,
    CalendarModule,
    DropdownModule,
  ],
  providers: [
    AuthGuard,
    MessageService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
