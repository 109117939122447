import {environment} from "../../environments/environment";

export const LINETokenApi = 'https://api.line.me/oauth2/v2.1/token';
export const LINEProfileApi = 'https://api.line.me/v2/profile';
export const LINEAuthApi = 'https://access.line.me/oauth2/v2.1/authorize';

export const BACKEND_URL = environment.backend_url || 'http://localhost:8000/api/';

export const BACKEND_API = {
  auth: BACKEND_URL + 'auth/seller/',
  create_seller: BACKEND_URL + 'create-seller/',
  me: BACKEND_URL + 'seller/me/',
  get_token: BACKEND_URL + 'get-order-user-credentials/',
  pdpa_info: BACKEND_URL + 'pdpa-infos/',
  reports: BACKEND_URL + 'reports/',
}
