import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import {CoreService} from "../../../core/src/services/core.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private coreService: CoreService,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url = state.url;
    const urlArray = state.url?.split('?')[0]?.split('/');
    if (urlArray.at(1) === 'seller'
        && urlArray.at(3) === 'verified'
        && url.includes('token')) {
      const accessToken = url.split('token=').at(-1);
      localStorage.setItem('accessToken', accessToken as string);
      localStorage.setItem('sellerID', urlArray.at(2) || '');
      localStorage.setItem('verification_status', 'VERIFIED');
      this.router.navigate(['/seller-details']).then();
      return true
    }
    if (!localStorage.getItem('accessToken')) {
      this.coreService.logout();
      return false;
    }
    return true;
  }

}
