import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard} from "./auth.guard";
import {SellerDetailsComponent} from "projects/core/src/standalone/seller-details/seller-details.component";
import {OrderGuard} from "./order.guard";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/login-or-register/login-or-register.module').then(
      m => m.LoginOrRegisterModule
    ),
  },
  {
    path: 'shops',
    loadChildren: () => import('../../../core/src/modules/shop-management/shop-management.module').then(
      m => m.ShopManagementModule
    ),
    canActivate: [AuthGuard]
  },
  {
    path: 'orders',
    loadChildren: () => import('projects/core/src/modules/order-management/order-management.module').then(
      m => m.OrderManagementModule
    ),
    canActivate: [OrderGuard]
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/seller-report/report.module').then(
      m => m.SellerReportModule
    ),
    canActivate: [OrderGuard]
  },
  {
    path: 'seller-details',
    component: SellerDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '/login'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
