<div class='header-container'>
  <div class="col-auto row no-gutters">
    <div class="burger-icon hover-fade"
         (click)="openNCloseSidebar()">
      <svg-icon src="assets/core/svgs/icon-hamburger.svg"></svg-icon>
    </div>
    <div class="col-auto">
      <img src="assets/core/images/chula-logo-small.png"
           width="auto"
           height="55"
           alt="Chulalongkorn University Logo">
    </div>
  </div>

  <div class="logout-icon pointer hover-fade"
       (click)="logoutUser()">
    <img src="assets/core/svgs/icon-logout.svg"
         width="auto"
         height="20"
         alt="logout icon">
  </div>
</div>
