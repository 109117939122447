export const BACKEND_API = {
  admin_categories: 'admin-categories/',
  subCategories: 'admin-sub-categories/',
  seller: 'seller/', // in BE, there is no Shops. there is only Sellers who created shops.
  seller_compact: 'get-seller-compact',
  seller_me: 'seller/me/',
  orders: 'orders/',
  products: 'products/',
  product_import: 'product-import/',
  product_bulk_update: 'product-bulk-update/',
}
