<div class="sidebar-content">
  <div class="pt-3">
    <ng-container *ngFor="let eachFeature of features">
      <p-panel [toggleable]="eachFeature.hasChildFeature"
               [expandIcon]="'pi pi-chevron-down'"
               [collapseIcon]="'pi pi-chevron-up'">
        <ng-template pTemplate="header">
          <div class="row no-gutters menu"
               [ngClass]="{'no-event': eachFeature.hasChildFeature,
                            'pointer': !eachFeature.hasChildFeature,
                            'selected-menu': !eachFeature.hasChildFeature
                                        && isSelected(eachFeature.uniqueURL)
                            }"
               [routerLink]="[eachFeature.featureURL]"
               (click)="goToLink(eachFeature.featureURL)">
            <img [src]="eachFeature.icon"
                 class="col-auto px-0"
                 width="auto"
                 height="20"
                 [alt]="eachFeature.featureName">
            <span class="col-auto pr-0 pl-3">{{ eachFeature.featureName }}</span>
          </div>
        </ng-template>
        <ng-container *ngIf="eachFeature.hasChildFeature">
          <ng-template pTemplate="content">
            <div class="sub-menu pointer"
                 (click)="goToLink(eachChildFeature.featureURL)"
                 [ngClass]="{'selected': isSelected(eachChildFeature.uniqueURL)}"
                 *ngFor="let eachChildFeature of eachFeature.subFeatures">
              {{ eachChildFeature.featureName }}
            </div>
          </ng-template>
        </ng-container>
      </p-panel>
    </ng-container>
  </div>
</div>
