import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CoreService} from "../../../../services/core.service";

@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Output() toggleSidebar = new EventEmitter();
  @Output() logout = new EventEmitter();

  constructor(
    private coreService: CoreService,
  ) {
  }


  logoutUser(): void {
    this.coreService.logout();
  }

  openNCloseSidebar(): void {
    this.toggleSidebar.emit();
  }
}
