import { Injectable } from '@angular/core';
import {BACKEND_API} from "../constants/api.constants";
import {AuthCredentials} from "../models/line-models";
import {BehaviorSubject, Observable} from "rxjs";
import {ApiService} from "../../../../core/src/services/api.service";
import {HttpClient} from "@angular/common/http";
import {CustomMessageService} from "projects/core/src/services/custom-message.service";
import {SellerInfo} from "projects/core/src/models/core.models";

@Injectable({
  providedIn: 'root'
})
export class SellerService {

  sellerInfo: BehaviorSubject<SellerInfo | null> = new BehaviorSubject<SellerInfo | null>(null);

  sellerID: number | null = null;

  constructor(
    private rawHttp: HttpClient,
    private apiService: ApiService,
    private messageService: CustomMessageService,
  ) {
    this.messageService.currentToastKey.next('seller-register');
  }

  get sellerId(): number | null {
    return this.sellerID || +(localStorage.getItem('sellerID') || 0) || null;
  }

  async getSellerInfo(): Promise<SellerInfo> {
    return await new Promise((resolve, reject) => {
      this.apiService.get<SellerInfo>(BACKEND_API.me).subscribe({
        next: (value) => {
          this.sellerInfo.next(value);
          resolve(value);
        },
        error: (error) => {
          reject(error);
        }
      });
    })
  }

  lineLogin(data: {access_token: string}): Observable<AuthCredentials> {
    return this.apiService.post<AuthCredentials>(BACKEND_API.auth, data);
  }

  createSeller(data: FormData) {
    return this.apiService.post<AuthCredentials>(BACKEND_API.create_seller, data);
  }

  updateSeller(data: FormData): Observable<SellerInfo> {
    return this.apiService.update(BACKEND_API.me, data);
  }

  saveConsent(data: FormData) {
    return this.apiService.post(BACKEND_API.pdpa_info, data);
  }

  getTokenFromUUID(uuid: string): Promise<{access: string}> {
    return new Promise((resolve, reject) => {
      const data = { 'uuid': uuid, 'user_type': 'seller' };
      this.apiService.post<{access: string}>(BACKEND_API.get_token, data).subscribe({
        next: (value) => {
          resolve(value);
        },
        error: (error) => {
          this.messageService.showError(error);
          reject(error);
        }
      });
    });
  }
}
