import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ActivatedRoute, Router, RouterModule} from "@angular/router";
import {CoreService} from "../../services/core.service";
import {CommonService} from "../../services/common.service";
import {CustomMessageService} from "../../services/custom-message.service";
import {FormsModule} from "@angular/forms";
import {CalendarModule} from "primeng/calendar";
import {ImageUploadComponent} from "../image-upload/image-upload.component";
import {DropdownModule} from "primeng/dropdown";
import {PreviewImageComponent} from "../preview-image/preview-image.component";
import {InputTextModule} from "primeng/inputtext";
import {AppType, Sellers, Status} from "../../models/core.models";
import {SellerService} from "./seller.service";
import {InputTextareaModule} from "primeng/inputtextarea";
import {ADMIN_STATUSES, SHOP_STATUSES} from "../../constants/core.constants";
import {DialogModule} from "primeng/dialog";

@Component({
  selector: 'lib-seller-details',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CalendarModule,
    ImageUploadComponent,
    PreviewImageComponent,
    DropdownModule,
    InputTextModule,
    RouterModule,
    InputTextareaModule,
    DialogModule,
  ],
  templateUrl: './seller-details.component.html',
  styleUrls: ['./seller-details.component.scss']
})
export class SellerDetailsComponent {

  appType: AppType;

  previewPhoto: File | string | null = null;

  sellerDetails: Sellers | null = null;

  showRejectPopup: boolean = false;
  showRejectedReason: boolean = false;
  showDeleteConfirmPopup: boolean = false;

  statuses: Status[] = ADMIN_STATUSES;

  shopStatuses:Status[] = SHOP_STATUSES

  sellerID: number | null = null;

  constructor(
    private router: Router,
    private coreService: CoreService,
    public commonService: CommonService,
    private sellerService: SellerService,
    private activatedRoute: ActivatedRoute,
    private messageService: CustomMessageService,
  ) {
    this.appType = this.coreService.type;
    this.coreService.isUserLoggedIn();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.sellerID = +params['id'] || +(localStorage.getItem('sellerID') || 0) || null;
      this.getSellerDetails();
    });
  }

  getSellerDetails(): void {
    if (!this.sellerID) {
      return;
    }
    this.sellerService.getSellerDetails(this.sellerID).subscribe({
      next: (res) => {
        this.sellerDetails = res;
        this.showRejectedReason = res.verification_status === 'SUSPENDED'
          || res.verification_status === 'REJECTED';
        this.recalculateTime();
      },
      error: (error) => {
        this.messageService.showError(error);
      }
    });
  }

  editSeller(data: FormData): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.sellerID) {
        reject('cannot find seller');
        return;
      }
      this.sellerService.patchSellerDetails(this.sellerID, data).subscribe({
        next: (res) => {
          this.sellerDetails = res;
          this.showRejectPopup = false;
          this.getSellerDetails();
          resolve();
          this.messageService.showSuccess('Successfully Updated');
        },
        error: (error) => {
          reject(error);
          this.messageService.showError(error);
        }
      });
    })
  }

  updateSeller(): void {
    if (!this.sellerDetails?.closing_time || !this.sellerDetails?.opening_time) {
      !this.sellerDetails?.opening_time && this.messageService.showError('Opening Time Cannot be Blank');
      !this.sellerDetails?.closing_time && this.messageService.showError('Closing Time Cannot be Blank');
      return;
    }
    if (this.sellerDetails?.verification_status === 'SUSPENDED' && !this.sellerDetails?.rejection_message) {
      this.messageService.showError('Please enter the rejected reason');
      return;
    }
    if (!this.sellerDetails.qr_number) {
      this.messageService.showError('Please fill in the QR number');
      return;
    }

    const fd = new FormData();
    fd.append('verification_status', this.sellerDetails?.verification_status || '')
    fd.append('rejection_message', this.sellerDetails?.rejection_message || '')
    fd.append('shop_name', this.sellerDetails?.shop_name || '')
    fd.append('seller_name', this.sellerDetails?.seller_name || '')
    fd.append('qr_number', this.sellerDetails?.qr_number || '')
    fd.append('phone_number', this.sellerDetails?.phone_number || '')
    fd.append('seller_line_id', this.sellerDetails?.seller_line_id || '')
    this.appType === 'seller' &&
      fd.append('is_closed', (Boolean(this.sellerDetails?.is_closed) + '') || 'false')

    Boolean(this.sellerDetails?.opening_time) &&
      fd.append('opening_time', this.changeDateToTime(this.sellerDetails?.opening_time) || '')
    Boolean(this.sellerDetails?.closing_time) &&
      fd.append('closing_time', this.changeDateToTime(this.sellerDetails?.closing_time) || '')
    Boolean(this.sellerDetails?.average_duration) &&
      fd.append('average_duration', this.sellerDetails?.average_duration?.toString() || '')

    this.sellerDetails?.qr_payment_photo && typeof this.sellerDetails.qr_payment_photo !== 'string' &&
      fd.append('qr_payment_photo', this.sellerDetails.qr_payment_photo);
    this.sellerDetails?.shop_cover_photo && typeof this.sellerDetails.shop_cover_photo !== 'string' &&
      fd.append('shop_cover_photo', this.sellerDetails.shop_cover_photo);
    this.editSeller(fd).then();
  }

  changeDateToTime(date: Date | string | undefined): string {
    if (!date) {
      return '';
    }
    if (typeof date === 'string') {
      return date;
    }
    return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
  }

  recalculateTime(): void {
    if (!this.sellerDetails) {
      return;
    }

    this.sellerDetails.opening_time = this.commonService.recalculateTime(this.sellerDetails.opening_time || '09:00');
    this.sellerDetails.closing_time = this.commonService.recalculateTime(this.sellerDetails.closing_time || '15:30');
  }

  dismissed(): void {
    this.previewPhoto = null;
  }

  selectFile(event: File | File[], type: 'shopCover' | 'qr'): void {
    const file = event as File;
    switch (type) {
      case 'shopCover':
        this.sellerDetails && (this.sellerDetails.shop_cover_photo = file);
        break;
      case 'qr':
        this.sellerDetails && (this.sellerDetails.qr_payment_photo = file);
        break;
    }
  }

  toggleRejectPopup(): void {
    this.showRejectPopup = !this.showRejectPopup;
  }

  verify(): void {
    const fd = new FormData();
    fd.append('rejection_message', '');
    fd.append('verification_status', 'VERIFIED');
    this.editSeller(fd).then();
  }

  reject(): void {
    if (!this.sellerDetails?.rejection_message) {
      this.messageService.showError('Please fill in the rejection message');
      return;
    }
    const fd = new FormData();
    fd.append('rejection_message', this.sellerDetails?.rejection_message || '');
    fd.append('verification_status', 'REJECTED');
    this.editSeller(fd).then();
  }

  delete(): void {
    const fd = new FormData();
    fd.append('verification_status', 'DELETED');
    this.editSeller(fd).then(
      () => {
        this.router.navigate(['/users', 'sellers']).then();
        this.messageService.showSuccess('Successfully Deleted');
      },
      (error) => {
        this.messageService.showError(error);
      }
    );
  }

  numberOnly(event: KeyboardEvent ): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
