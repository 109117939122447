import {Component, EventEmitter, Input, Output} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lib-image-upload',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css']
})
export class ImageUploadComponent {

  uploaderID: string = '';

  @Input() acceptType: string = 'image/*';
  @Input() multiple: boolean = false;
  @Output()newFile = new EventEmitter<File | File[]>();

  constructor() {
    this.uploaderID = (Math.random() + 1).toString(36).substring(7);
  }

  selectFile(event: unknown): void {
    if (!this.multiple) {
      const file: File = ((event as any).files)[0]
      this.newFile.emit(file);
      return;
    }
    const newFiles: File[] = [];
    for (const eachFile of ((event as any).files)) {
      newFiles.push(eachFile);
    }
    this.newFile.emit(newFiles);
  }
}
