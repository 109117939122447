<div class="w-100percent h-100percent">
  <div class="upload-container"
       (click)="imageUpload.click()">
    <img src="assets/core/svgs/icon-camera.svg"
         width="20"
         height="auto"
         alt="upload shop owner image">
    <span class="m-l-10">คลิกเพื่อเพิ่มรูปภาพ</span>
  </div>
  <input type="file"
         accept="{{ acceptType }}"
         [multiple]="multiple"
         #imageUpload
         hidden
         (change)="selectFile($event.target)"
         id="{{ uploaderID }}">
</div>

