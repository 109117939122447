import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {AppType, Sellers} from "../../models/core.models";
import {ApiService} from "../../services/api.service";
import {CoreService} from "../../services/core.service";
import {BACKEND_API} from "../../constants/api.constants";

@Injectable({
  providedIn: 'root'
})
export class SellerService {

  appType: AppType

  constructor(
    private apiService: ApiService,
    private coreService: CoreService
  ) {
    this.appType = this.coreService.type;
  }

  getEndpoint(url: string): string {
    return this.coreService.getEndpoint(url);
  }

  getSellerDetails(id: number): Observable<Sellers> {
    const endpoint = this.appType === 'admin' ? `${BACKEND_API.seller + id}/` : BACKEND_API.seller_me;
    return this.apiService.get(this.getEndpoint(endpoint));
  }

  patchSellerDetails(id: number, data: FormData): Observable<Sellers> {
    const endpoint = this.appType === 'admin' ? `${BACKEND_API.seller + id}/` : BACKEND_API.seller_me;
    return this.apiService.update(this.getEndpoint(endpoint), data);
  }
}
