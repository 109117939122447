<div class="page-base">
  <div class="main-contents"
       *ngIf="sellerDetails ">
    <div class="row no-gutters mb-5"
         [ngClass]="appType === 'admin' ? 'justify-content-between' : 'justify-content-end'">
      <div class="col-auto"
           *ngIf="appType === 'admin'">
        <div class="icon-bg rounded-circle pointer hover-fade w-30 h-30 d-flex-center"
             [routerLink]="['/users', 'sellers']">
          <i class="pi pi-chevron-left"></i>
        </div>
      </div>

      <div class="col-auto"
           *ngIf="appType === 'admin' && sellerDetails.verification_status === 'PENDING'">
        <button class="btn btn-reject"
                (click)="toggleRejectPopup()">
          Reject
        </button>
        <button class="btn btn-approve m-l-10"
                (click)="verify()">
          Approve
        </button>
      </div>


      <div class="col-auto row no-gutters flex-row"
           *ngIf="appType === 'seller' || sellerDetails.verification_status !== 'PENDING'">
        <button class="btn w-110 theme-button m-l-10"
                (click)="updateSeller()">
          บันทึก
        </button>
      </div>
    </div>

    <div class="form-group">
      <!-- SHOP NAME -->
      <div class="form-box">
        <label for="shop-name"
               class="w-100percent p-x-0 mb-1">
          ชื่อร้านค้า
        </label>

        <input type="text"
               id="shop-name"
               pInputText
               [(ngModel)]="sellerDetails.shop_name"
               placeholder="ชื่อร้านค้า">
      </div>

      <!-- SELLER NAME -->
      <div class="form-box">
        <label for="seller-name"
               class="w-100percent p-x-0 mb-1">
          ชื่อผู้ค้า
        </label>

        <input type="text"
               id="seller-name"
               [(ngModel)]="sellerDetails.seller_name"
               pInputText
               placeholder="ชื่อผู้ค้า">
      </div>

      <!-- LINE ID -->
      <div class="form-box">
        <label for="line-name"
               class="w-100percent p-x-0 mb-1">
          Line Name
        </label>

        <input type="text"
               id="line-name"
               class="disabled"
               disabled
               [(ngModel)]="sellerDetails.line_profile_name"
               pInputText
               placeholder="Line Name">
      </div>

      <!-- PHONE NUMBER -->
      <div class="form-box">
        <label for="phone-number"
               class="w-100percent p-x-0 mb-1">
          เบอร์โทร
        </label>

        <input type="text"
               id="phone-number"
               [(ngModel)]="sellerDetails.phone_number"
               pInputText
               placeholder="เบอร์โทร">
      </div>

      <!-- COOKING TIME -->
      <div class="form-box half">
        <label for="cooking-time"
               class="w-100percent p-x-0 mb-1">
          ระยะเวลาในการเตรียมอาหาร (นาที)
        </label>

        <input type="text"
               (keydown)="numberOnly($event)"
               id="cooking-time"
               [(ngModel)]="sellerDetails.average_duration"
               pInputText
               placeholder="นาที">
      </div>

      <!-- QR NUMBER -->
      <div class="form-box half">
        <label for="qr-number"
               class="w-100percent p-x-0 mb-1">
          หมายเลขพร้อมเพย์ 
          <small class="text-danger">*</small>
        </label>

        <input type="text"
               id="qr-number"
               (keydown)="numberOnly($event)"
               [(ngModel)]="sellerDetails.qr_number"
               pInputText>
      </div>

      <!-- OPENING HOURS -->
      <div class="form-box half">
        <label for="opening-hours"
               class="w-100percent p-x-0 mb-1">
          เวลาเปิดร้าน
        </label>

        <p-calendar [timeOnly]="true"
                    [readonlyInput]="false"
                    [(ngModel)]="sellerDetails.opening_time"
                    [stepMinute]="1"
                    class="w-100percent"
                    id="opening-hours"
                    [hourFormat]="'12'"
                    inputId="timeonly">
        </p-calendar>
      </div>

      <!-- CLOSING HOURS -->
      <div class="form-box half">
        <label for="closing-hours"
               class="w-100percent p-x-0 mb-1">
          เวลาปิดร้าน
        </label>

        <p-calendar [timeOnly]="true"
                    [readonlyInput]="false"
                    [stepMinute]="1"
                    [(ngModel)]="sellerDetails.closing_time"
                    class="w-100percent"
                    id="closing-hours"
                    [hourFormat]="'12'"
                    inputId="timeonly">
        </p-calendar>
      </div>

      <!-- LOCATION -->
      <div class="form-box">
        <label for="cooking-time"
               class="w-100percent p-x-0 mb-1">
          ที่อยู่ของร้าน
        </label>

        <textarea id="address"
                  rows="3"
                  [(ngModel)]="sellerDetails.address"
                  cols="30"
                  pInputTextarea></textarea>
      </div>

      <!-- SHOP COVER PHOTO -->
      <div class="form-box custom-max-width">
        <label class="mb-1"
               for="shop-cover">รูปหน้าร้านค้า</label>
        <div class="mb-2">
          <lib-image-upload (newFile)="selectFile($event, 'shopCover')"
                            id="shop-cover"
                            [acceptType]="'image/*'">
          </lib-image-upload>
        </div>

        <ng-container *ngIf="sellerDetails.shop_cover_photo">
          <ng-container [ngTemplateOutlet]="photoFiles"
                        [ngTemplateOutletContext]="{photo: sellerDetails.shop_cover_photo,
                                                  type: 'shopCover'}">
          </ng-container>
        </ng-container>
      </div>

      <!-- QR CODE -->
      <div class="form-box custom-max-width">
        <label class="mb-1"
               for="qr-code">รูป QR CODE ชำระเงิน</label>
        <div class="mb-2">
          <div class="mb-2">
            <lib-image-upload (newFile)="selectFile($event, 'qr')"
                              id="qr-code"
                              [acceptType]="'image/*'">
            </lib-image-upload>
          </div>

          <ng-container *ngIf="sellerDetails.qr_payment_photo">
            <ng-container [ngTemplateOutlet]="photoFiles"
                          [ngTemplateOutletContext]="{photo: sellerDetails.qr_payment_photo,
                                                  type: 'shopCover'}">
            </ng-container>
          </ng-container>
        </div>
      </div>


      <!--OWNER IMAGE-->
      <div class="form-box custom-max-width">
        <label class="mb-1">รูปเจ้าของร้านค้า</label>
        <ng-container [ngTemplateOutlet]="photoFiles"
                      [ngTemplateOutletContext]="{photo: sellerDetails.owner_image,
                                                  type: 'owner'}">
        </ng-container>
      </div>

      <!--SHOP IMAGE-->
      <div class="form-box custom-max-width">
        <label class="mb-1 row no-gutters justify-content-between"
               for="shop-photos">
          <span class="col-auto">รูปถ่ายร้านค้า</span>
        </label>
        <ng-container *ngFor="let eachPhoto of sellerDetails.shop_photos">
          <ng-container [ngTemplateOutlet]="photoFiles"
                        id="shop-photos"
                        [ngTemplateOutletContext]="{photo: eachPhoto.photo,
                                                  type: 'shop'}">
          </ng-container>
        </ng-container>
      </div>

      <!--NATIONAL ID IMAGE-->
      <div class="form-box custom-max-width">
        <label class="mb-1"
               for="national-id">รูปถ่ายหน้าบัตรประชาชนหรือสัญญาร้านค้ากับจุฬาฯ</label>
        <ng-container [ngTemplateOutlet]="photoFiles"
                      id="national-id"
                      [ngTemplateOutletContext]="{photo: sellerDetails.seller_national_id,
                                                type: 'nationalID'}">
        </ng-container>
      </div>

      <div class="form-box custom-max-width"
           *ngIf="appType === 'admin'">
        <label for="status"
               class="w-100percent p-x-0 mb-1">
          สถานะ
        </label>

        <p-dropdown [options]="statuses"
                    [(ngModel)]="sellerDetails.verification_status"
                    id="status"
                    class="p-x-0"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="สถานะ"
                    [showClear]="false"></p-dropdown>
      </div>

      <div class="form-box custom-max-width"
           *ngIf="appType === 'seller'">
        <label for="shop-status"
               class="w-100percent p-x-0 mb-1">
          สถานะ
        </label>

        <p-dropdown [options]="shopStatuses"
                    [(ngModel)]="sellerDetails.is_closed"
                    id="shop-status"
                    [ngClass]="!sellerDetails.is_closed ? 'p-text-success' : 'p-text-danger'"
                    class="p-x-0"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="สถานะ"
                    [showClear]="false"></p-dropdown>
      </div>

      <div class="form-box custom-max-width"
           *ngIf="appType === 'admin' && sellerDetails.verification_status === 'SUSPENDED' && !showRejectedReason">
        <label for="reason"
               class="w-100percent p-x-0 mb-1">
          เหตุผลที่ระงับการใช้งาน
        </label>

        <input type="text"
               id="reason"
               class="w-100percent"
               [(ngModel)]="sellerDetails.rejection_message"
               pInputText
               placeholder="เหตุผลที่ระงับการใช้งาน">
      </div>

      <div class="form-box custom-max-width"
           *ngIf="showRejectedReason">
        <label class="w-100percent p-x-0 mb-1">
          เหตุผล Reject
        </label>

        <div class="row no-gutters border py-2 px-2 border-rounded w-100percent justify-content-between">
          <div class="col-auto">
            {{ sellerDetails.rejection_message }}
          </div>
          <div class="col-auto p-r-0">
              <span class="file-type">
                {{ sellerDetails.rejected_date | date: 'dd/MM/yyyy' }}
              </span>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>

<ng-template #photoFiles
             let-photo='photo'>
  <div class="w-100percent mt-2">
    <lib-preview-image [previewFile]="photo"
                       (dismissed)="dismissed()">
      <div class="selected-file">
        <div class="col-auto d-flex align-items-center">
      <span class="file-type d-inline-block">
        {{ commonService.getPhotoExtension(photo) || 'JPG' }}
      </span>
          <span class="d-inline-block p-l-10 text-truncate w-250">
        {{ commonService.getFileName(photo) }}
      </span>
        </div>
      </div>
    </lib-preview-image>
  </div>
</ng-template>

<p-dialog [(visible)]="showRejectPopup"
          [closable]="false"
          [modal]="true">
  <ng-template pTemplate="header">
    <h4 class="mx-auto">ปฏิเสธการสมัครร้านค้า</h4>
  </ng-template>

  <div class="popup-min-w"
       *ngIf="sellerDetails">
    <div class="row no-gutters mb-4">
      <label for="rejected-reason"
             class="w-100percent p-x-0 mb-2">
        เหตุผล
      </label>
      <input type="text"
             id="rejected-reason"
             [(ngModel)]="sellerDetails.rejection_message"
             pInputText
             placeholder="กรุณาแนบสำเนาบัตรประชาชนใหม่">
    </div>

    <button class="btn theme-button mt-5"
            (click)="reject()">
      ยืนยัน
    </button>

    <p class="w-100percent pointer hover-fade text-center my-3 fw-bold text-theme"
       (click)="toggleRejectPopup()">
      ยกเลิก
    </p>
  </div>
</p-dialog>

<p-dialog [(visible)]="showDeleteConfirmPopup"
          [closable]="false"
          [modal]="true">
  <ng-template pTemplate="header">
    <h4 class="mx-auto">ลบร้านค้า</h4>
  </ng-template>

  <div class="popup-min-w"
       *ngIf="sellerDetails">
    <h5 class="w-100percent text-center p-x-0 my-3">
      กดปุ่มลบเพื่อดำเนินการ
    </h5>

    <button class="btn theme-button mt-5"
            (click)="delete()">
      ลบ
    </button>

    <p class="w-100percent pointer hover-fade text-center my-3 fw-bold text-theme"
       (click)="toggleRejectPopup()">
      ยกเลิก
    </p>
  </div>
</p-dialog>
