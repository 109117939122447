import { Injectable } from '@angular/core';
import {AppType} from "../models/core.models";
import {BehaviorSubject} from "rxjs";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  public type: AppType;
  public backend_url: string = '';

  userLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
  ) { }

  setBackendUrl(url: string): void {
    this.backend_url = url;
  }

  getEndpoint(endPoint: string): string {
    return this.backend_url + endPoint;
  }

  logout(): void {
    localStorage.clear();
    this.router.navigate(['/login']).then(() => {});
    this.userLoggedIn.next(false);
  }

  isUserLoggedIn(): boolean {
    const token = localStorage.getItem('accessToken');
    if (this.type === 'seller') {
      const status = localStorage.getItem('verification_status') || '';
      const isLoggedIn = status === 'VERIFIED';
      this.userLoggedIn.next(Boolean(isLoggedIn));
      return isLoggedIn;
    }
    this.userLoggedIn.next(Boolean(token));
    return Boolean(token);
  }

  setLocalStorage(accessToken: string, refreshToken: string, isLoggedIn: boolean = false): void {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    this.userLoggedIn.next(isLoggedIn);
  }

}
