import { NgModule } from '@angular/core';
import { CoreComponent } from './core.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { HeaderComponent } from './layout/header/header.component';
import { BaseLayoutComponent } from "./layout/base-layout/base-layout.component";
import { ApiService } from "../../services/api.service";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {Accordion} from "primeng/accordion";
import {PanelModule} from "primeng/panel";
import {MenuModule} from "primeng/menu";
import {ButtonModule} from "primeng/button";
import {AngularSvgIconModule} from "angular-svg-icon";
import {HttpClientModule} from "@angular/common/http";
import {SidebarModule} from "primeng/sidebar";



@NgModule({
  declarations: [
    BaseLayoutComponent,
    CoreComponent,
    HeaderComponent,
    SidebarComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    PanelModule,
    MenuModule,
    ButtonModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
  ],
  exports: [
    BaseLayoutComponent,
    CoreComponent,
  ],
  providers: [
    ApiService,
    Accordion,
  ]
})
export class CoreModule { }
