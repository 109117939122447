<div class="preview-container">
  <ng-content></ng-content>
  <div class="preview-box"
       [ngClass]="{'show': showPreview}">
    <p-image [src]="previewSafe"
             (onShow)="showPreview = true"
             (onHide)="dismiss()"
             (onImageError)="showError($event)"
             [preview]="true"
             alt="Preview Product Image">
      <ng-template pTemplate="indicator">
      </ng-template>
    </p-image>
  </div>
</div>
