import {Component, OnInit} from '@angular/core';
import { CoreService } from "../../../core/src/services/core.service";
import {ApiService} from "../../../core/src/services/api.service";
import {CustomMessageService} from "../../../core/src/services/custom-message.service";
import {SellerService} from "./services/seller.service";
import {environment} from "../environments/environment";
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'seller';
  currentToastKey: string = '';

  constructor(
    private router: Router,
    public coreService: CoreService,
    private sellerService: SellerService,
    private messageService: CustomMessageService,
  ) {
    this.coreService.type = 'seller';
    this.coreService.setBackendUrl(environment.backend_url);
    this.title = this.coreService.type;
    this.messageService.currentToastKey.subscribe((value) => {
      this.currentToastKey = value;
    });
    this.coreService.isUserLoggedIn();
  }

  ngOnInit(): void {
    if (!this.coreService.isUserLoggedIn()) {
      return;
    }
    this.sellerService.getSellerInfo().then(
      (res) => {
        if (res.verification_status === 'SUSPENDED') {
          this.messageService.showError('Your account has been suspended. Please contact support');
          localStorage.clear();
          this.coreService.isUserLoggedIn();
          this.router.navigate(['/suspended']).then();
          return;
        }
        localStorage.setItem('sellerID', res.id?.toString());
        localStorage.setItem('verification_status', res.verification_status);
        this.coreService.isUserLoggedIn();
      },
      (error) => {
        this.messageService.showError(error);
      },
    );
  }
}
