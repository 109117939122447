import {Component, EventEmitter, HostListener, OnInit, Output} from '@angular/core';

@Component({
  selector: 'lib-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss']
})
export class BaseLayoutComponent implements OnInit {
  @Output() logout = new EventEmitter();

  menuBreakPoint = 800;

  openSidebar: boolean = true;

  innerWidth: number | null = null;


  constructor() { }

  ngOnInit(): void {
    this.onResize();
  }

  logoutUser(): void {
    this.logout.emit();
  }

  toggleSidebar(): void {
    this.openSidebar = !this.openSidebar;
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.innerWidth = window.innerWidth;
    this.openSidebar = this.innerWidth > this.menuBreakPoint;
  }
}
