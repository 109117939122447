import {Component} from '@angular/core';
import {CoreService} from "../../../../services/core.service";
import {AppType, Features} from "../../../../models/core.models";
import {ADMIN_FEATURES, SELLER_FEATURES} from "../../../../constants/side-bar.constants";
import {Router} from "@angular/router";

@Component({
  selector: 'lib-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  type: AppType = 'seller';

  features: Features[] = [];

  constructor(
    private coreService: CoreService,
    private router: Router,
  ) {
    this.type = coreService.type;
    this.features = this.type === 'admin' ? ADMIN_FEATURES : SELLER_FEATURES;
  }

  isSelected(uniqueUrl: string): boolean {
    return this.router.url.includes(uniqueUrl);
  }

  goToLink(featureUrl: string) {
    if (featureUrl.includes(':sellerID')) {
      featureUrl = featureUrl.replace(':sellerID',
        localStorage.getItem('sellerID') || 'Not-Found');
    }
    this.router.navigate([featureUrl]).then();
  }

}
