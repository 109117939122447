import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ImageModule} from "primeng/image";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";

@Component({
  selector: 'lib-preview-image',
  standalone: true,
  imports: [CommonModule, ImageModule],
  templateUrl: './preview-image.component.html',
  styleUrls: ['./preview-image.component.scss']
})
export class PreviewImageComponent implements OnChanges {

  @Input() previewFile: File | string | null = null;
  @Output() dismissed = new EventEmitter();

  previewSafe: string | SafeUrl = '';

  showPreview: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.previewURL();
  }

  dismiss(): void {
    this.showPreview = false;
    this.dismissed.emit();
  }

  previewURL(): void {
    if (!this.previewFile) {
      this.previewSafe = '';
      return;
    }
    if (typeof this.previewFile === 'string') {
      this.previewSafe = this.previewFile;
      return;
    }
    this.previewSafe = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.previewFile as File));
  }

  showError(error: any) {
    console.warn(error);
  }
}
