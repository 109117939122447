<div class="webpage-view">
  <lib-header (toggleSidebar)="toggleSidebar()"
              (logout)="logoutUser()"></lib-header>

  <div class="main-base-layout">
    <div class="sidebar"
         [ngClass]="{'show': openSidebar }">
      <lib-sidebar></lib-sidebar>
    </div>
    <div class="contents">
      <ng-content></ng-content>
    </div>
  </div>
</div>
