import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import { Router } from '@angular/router';
import {CoreService} from "./core.service";

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private coreService: CoreService,
  ) {}

  getHeaders(): HttpHeaders {
    const token = localStorage.getItem('accessToken') || '';
    const header = new HttpHeaders();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    if (token) {
      return header.append(
        'Authorization',
        `Bearer ${token}`
      );
    }
    return header
  }

  post<T = unknown>(
    url: string,
    data: unknown = null,
    customHeader: HttpHeaders | null = null,
  ): Observable<T> {
    return this.http
      .post<T>(url, data, { headers: customHeader || this.getHeaders() })
      .pipe(
        catchError((error) => {
          this.checkRequest(error);
          return throwError(error);
        })
      );
  }

  postBlob(url: string, data: unknown): Observable<Blob> {
    const customHeader = this.getHeaders();
    customHeader.set('Content-Type', '');
    return this.http
      .post(url, data, {
        headers: customHeader,
        responseType: 'blob',
      })
      .pipe(
        catchError((error) => {
          this.checkRequest(error);
          return throwError(error);
        })
      );
  }

  get<T = unknown>(url: string, params?: HttpParams): Observable<T> {
    return this.http.get<T>(url, { headers: this.getHeaders(), params }).pipe(
      catchError((error) => {
        this.checkRequest(error);
        return throwError(error);
      })
    );
  }

  getBlob(url: string, params?: HttpParams): Observable<Blob> {
    return this.http
      .get(url, { headers: this.getHeaders(), params, responseType: 'blob' })
      .pipe(
        catchError((error) => {
          this.checkRequest(error);
          return throwError(error);
        })
      );
  }

  update<T = unknown>(url: string, data: unknown): Observable<T> {
    return this.http.patch<T>(url, data, { headers: this.getHeaders() }).pipe(
      catchError((error) => {
        this.checkRequest(error);
        return throwError(error);
      })
    );
  }

  delete<T = unknown>(url: string): Observable<T> {
    return this.http.delete<T>(url, { headers: this.getHeaders() }).pipe(
      catchError((error) => {
        this.checkRequest(error);
        return throwError(error);
      })
    );
  }

  checkRequest(error: HttpErrorResponse): void {
    if (error.status === 401 || error.status === 403 || error?.error?.message === 'Invalid Token') {
      this.coreService.logout();
    }
    try {
      this.showError(error).then();
    } catch (e) {
      console.error(e);
    }
  }

  async showError(error: HttpErrorResponse): Promise<void> {
    if (error.status === 500) {
      // this.notificationService.error('Internal Server Error');
      return;
    }
    if (error.error) {
      if (error.error instanceof Blob) {
        // this.notificationService.error(await error.error.text());
        return;
      }

      if (Array.isArray(error.error)) {
        error.error.forEach((eachError) => {
          // this.notificationService.error(eachError);
        });
        return;
      }
      if (Object.keys(error.error)) {
        Object.keys(error.error).forEach((key) => {
          // this.notificationService.error(error.error[key], key);
        });
        return;
      }
    }

    if (typeof error === 'string') {
      // this.notificationService.error(error, 'Error');
      return;
    }

    // this.notificationService.error(error.statusText);
  }
}
