import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CoreService } from "projects/core/src/services/core.service";
import { SellerService } from "./services/seller.service";

@Injectable({
  providedIn: 'root'
})
export class OrderGuard implements CanActivate {
  constructor(
    private router: Router,
    private coreService: CoreService,
    private sellerService: SellerService,
  ) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    const uuid = state.url.includes('uuid') ? state.url?.split('?')[1]?.split('=')?.at(-1) : 0;
    if (uuid) {
      const token = await this.sellerService.getTokenFromUUID(uuid).then() as { access: string };
      localStorage.setItem('accessToken', token.access);
      localStorage.setItem('verification_status', 'VERIFIED');
    }
    if (!localStorage.getItem('accessToken')) {
      this.coreService.logout();
      return false;
    }
    return this.coreService.isUserLoggedIn();
  }

}
