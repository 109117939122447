
import {NumberDictionary, Status} from "../models/core.models";

export const SHOP_STATUSES: Status[] = [
  {
    value: false,
    label: 'เปิดใช้งาน',
  },
  {
    value: true,
    label: 'ปิดใช้งาน',
  }
];

export const ADMIN_STATUSES: Status[] = [
  {
    value: 'VERIFIED',
    label: 'Verified',
  },
  {
    value: 'SUSPENDED',
    label: 'Suspended',
  },
];

export const ACTIVE_STATUSES: Status[] = [
  {
    value: true,
    label: 'Active',
  },
  {
    value: false,
    label: 'In-Active',
  },
];

export const REPORT_STATUSES: NumberDictionary[] = [
  {
    value: 1,
    label: 'กำลังดำเนินการ',
  },
  {
    value: 2,
    label: 'แก้ไขแล้ว',
  }
]

export const REPORT_STATUS: { [key in string]: string } = {
  0: '-',
  1: 'กำลังดำเนินการ',
  2: 'แก้ไขแล้ว'
}
