import { Injectable } from '@angular/core';
import {MessageService} from "primeng/api";
import {HttpErrorResponse} from "@angular/common/http";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CustomMessageService {

  currentToastKey: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private messageService: MessageService,
  ) {
  }

  showSuccess(message: string): void {
    this.messageService.add({
      key: this.currentToastKey.value,
      severity: 'success',
      summary: 'Success',
      detail: message
    });
  }

  showError(error: unknown): void {
    if (error instanceof HttpErrorResponse && error.status === 500) {
      this.messageService.add({
        key: this.currentToastKey.value,
        severity:'error',
        summary: 'Error',
        detail: 'Server Error',
      });
      return;
    }
    if (error instanceof HttpErrorResponse && typeof error.error === 'string' && error.status === 404) {
      this.messageService.add({
        key: this.currentToastKey.value,
        severity:'error',
        summary: error.status?.toString(),
        detail: error.statusText,
      });
      return;
    }
    if (error instanceof HttpErrorResponse && error.error && Object.keys(error.error)) {
      Object.keys(error?.error || []).forEach(key => {
        this.messageService.add({
          key: this.currentToastKey.value,
          severity:'error',
          summary: key,
          detail: error.error[key],
        });
      });
      return;
    }
    if (typeof error === 'string') {
      this.messageService.add({
        key: this.currentToastKey.value,
        severity:'error',
        summary: 'Error',
        detail: error,
      });
      return;
    }
    this.messageService.add({
      key: this.currentToastKey.value,
      severity:'error',
      summary: 'Error',
      detail: "Error",
    });
  }
}
