import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor() {}

  dateToString(date: Date | undefined, type: 'from' | 'to' = 'from'): string {
    if (!date) return '';
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}T${type === 'from' ? '00:00:00' : '23:59:59'}`;
  }

  getDateRange(date: Date[]): string {
    if (!date.length) {
      return '';
    }
    return date.map(each => each.toLocaleString('en-CA').split(',')[0]).join('-');
  }

  getPhotoExtension(file: string | File): string {
    if (!file) {
      return '';
    }
    if (typeof  file === 'string') {
      const urlArray = file?.split('/');
      if (!urlArray?.length) {
        return 'JPG';
      }
      return urlArray[urlArray.length - 1]?.split('.')[1] || 'ASD';
    }
    return file?.name?.split('.')?.at(-1) || '';
  }

  getFileName(file: string | File): string {
    if (typeof file === 'string') {
      const urlArray = file?.split('/');
      if (!urlArray?.length) {
        return 'JPG';
      }
      return urlArray[urlArray.length - 1]?.split('.')[0] || 'ASD';
    }
    return file?.name || '';
  }

  recalculateTime(time: string | Date | undefined): Date | string {
    if (!time) {
      return '';
    }
    if (time instanceof Date) {
      return time
    }
    return new Date('2023-01-01T' + time);
  }
}
