import {Features} from "../models/core.models";

export const ADMIN_FEATURES: Features[] = [
  {
    featureName: 'การตั้งค่าผู้ใช้งาน',
    icon: 'assets/core/svgs/icon-users.svg',
    hasChildFeature: true,
    uniqueURL: 'null',
    featureURL: '',
    subFeatures: [
      {
        featureName: 'ผู้ซื้อ',
        icon: '',
        selected: false,
        uniqueURL: '/users/buyers',
        featureURL: '/users/buyers'
      },
      {
        featureName: 'ผู้ขาย',
        icon: '',
        selected: false,
        uniqueURL: '/users/sellers',
        featureURL: '/users/sellers'
      }
    ],
  },
  {
    featureName: 'หมวดหมู่',
    icon: 'assets/core/svgs/icon-settings.svg',
    hasChildFeature: false,
    uniqueURL: 'categories',
    featureURL: '/categories/list',
    subFeatures: [],
  },
  {
    featureName: 'ประวัติคำสั่งซื้อ',
    icon: 'assets/core/svgs/icon-history.svg',
    hasChildFeature: false,
    uniqueURL: 'orders',
    featureURL: '/orders/list',
    subFeatures: [],
  },
  {
    featureName: 'การจัดการร้านค้า',
    icon: 'assets/core/svgs/icon-folder.svg',
    hasChildFeature: true,
    uniqueURL: 'null',
    featureURL: '',
    subFeatures: [
      {
        featureName: 'ร้านค้า',
        icon: '',
        selected: false,
        uniqueURL: 'shops',
        featureURL: '/shops/list'
      },
    ],
  },
  {
    featureName: 'แจ้งปัญหา',
    icon: 'assets/core/svgs/icon-report.svg',
    hasChildFeature: true,
    uniqueURL: 'null',
    featureURL: '',
    subFeatures: [
      {
        featureName: 'ผู้ซื้อ',
        icon: '',
        selected: false,
        uniqueURL: 'report/list/buyer',
        featureURL: '/report/list/buyer'
      },
      {
        featureName: 'ผู้ขาย',
        icon: '',
        selected: false,
        uniqueURL: 'report/list/seller',
        featureURL: '/report/list/seller'
      },
    ],
  },
  {
    featureName: 'การตั้งค่าระบบ',
    icon: 'assets/core/svgs/icon-settings.svg',
    hasChildFeature: true,
    featureURL: '',
    uniqueURL: 'null',
    subFeatures: [
      {
        featureName: 'โดเมนอีเมล',
        icon: '',
        selected: false,
        uniqueURL: '/settings/domain-settings',
        featureURL: '/settings/domain-settings'
      },
      {
        featureName: 'เปลี่ยนรหัสผ่าน',
        icon: '',
        selected: false,
        uniqueURL: '/settings/admin-reset-password',
        featureURL: '/settings/admin-reset-password'
      }
    ],
  },
]


export const SELLER_FEATURES: Features[] = [
  {
    featureName: 'การตั้งค่าผู้ใช้งาน',
    icon: 'assets/core/svgs/icon-users.svg',
    hasChildFeature: false,
    featureURL: 'seller-details',
    uniqueURL: 'seller-details',
    subFeatures: [],
  },
  {
    featureName: 'การจัดการร้านค้า',
    icon: 'assets/core/svgs/icon-folder.svg',
    hasChildFeature: false,
    uniqueURL: 'shops',
    featureURL: 'shops/:sellerID',
    subFeatures: [],
  },
  {
    featureName: 'ประวัติคำสั่งซื้อ',
    icon: 'assets/core/svgs/icon-history.svg',
    hasChildFeature: false,
    uniqueURL: 'orders',
    featureURL: 'orders/list',
    subFeatures: [],
  },
  {
    featureName: 'แจ้งปัญหา',
    icon: 'assets/core/svgs/icon-report.svg',
    hasChildFeature: false,
    uniqueURL: 'reports',
    featureURL: 'reports/',
    subFeatures: [],
  }
]
